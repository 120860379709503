<template>
  <v-card>
    <v-card-title>
      <menu-title
        title="Licensing History"
        class="mt-6"
      ></menu-title>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="10"
          align="left"
        >
          <v-text-field
            outlined
            hide-details
            v-model="search"
            label="Search..."
            prepend-icon="mdi-account-search-outline"
            class="pl-2"
            clearable
          ></v-text-field>
        </v-col>
        <v-col
          cols="2"
          align="right"
        >
          <v-select
            outlined
            hide-details
            v-model="filterBy"
            :items="filters"
            :item-text="item => item.text"
            :item-value="item => item.value"
            label="Show"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="expanded">
        <v-col cols="5">
          <v-select
            outlined
            hide-details
            v-model="filterByEmp"
            :items="getUsers"
            :item-text="item => item.name"
            :item-value="item => item.id"
            label="License granted by"
          ></v-select>
        </v-col>
        <v-col cols="5">
          <v-select
            outlined
            hide-details
            v-model="sortBy"
            :items="sorts"
            :item-text="item => item.text"
            :item-value="item => item.value"
            label="Sort by"
          ></v-select>
        </v-col>
        <v-col
          cols="2"
          align="center"
        >
          <v-switch
            outlined
            hide-details
            v-model="showExpired"
            label="Show expired licenses?"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          align="center"
        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                outlined
                icon
                large
                @click="expanded = !expanded"
                class="mb-3 mt-0"
              >
                <v-icon>
                  {{ expanded ? 'mdi-minus' : 'mdi-plus'}}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ 
                expanded ? 
                'Hide additional search and filter options' : 
                'Show additional search and filter options' 
              }}
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <thead>
              <tr>
                <!--<th>License ID/s</th>-->
                <th>License Name</th>
                <th>Partner</th>
                <th>Customer</th>
                <th>Requested</th>
                <th>Amount</th>
                <th>Runtime</th>
                <th>Price</th>
                <th>Expires</th>
              </tr>
            </thead>
            <tbody v-if="ready">
              <tr
                v-for="(license, index) in shownCustomerLicenses"
                :key="index"
                :class="getClass(license)"
              >
                <!--
                <td align="left">
                  <v-tooltip
                    right
                    v-if="getLicenseIdsHoverString(license)"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-on="on"
                        v-bind="attrs"
                      >{{ getLicenseIdsString(license) }}</span>
                    </template>
                    <span>{{ getLicenseIdsHoverString(license) }}</span>
                  </v-tooltip>
                  <span v-else>{{ getLicenseIdsString(license) }}</span>
                </td>
                -->
                <td align="left">{{ getTemplateName(license) }}</td>
                <td align="left">{{ getPartnerName(license) }}</td>
                <td align="left">{{ getCustomerName(license) }}</td>
                <td align="left">{{ getRequestDate(license) }}</td>
                <td align="left">{{ license.amount }}</td>
                <td align="left">{{ license.months }}</td>
                <td align="left">{{ getPrice(license) }}</td>
                <td align="left">{{ getExpiryDate(license) }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import menuTitle from "../../components/helpers/menu_title.vue";
export default {
  name: "history",
  components: {
    menuTitle,
  },
  props: {},
  computed: {
    ...mapGetters({
      privilegedUsers: "privilegedUsers",
      groupedCustomerlicenses: "groupedCustomerlicenses",
      customerById: "customerById",
      subcustomerById: "subcustomerById",
      templateById: "templateById",
      applicationById: "applicationById",
    }),
    getUsers: function () {
      var users = [{ id: -1, name: "All Users" }];
      users.push(...this.privilegedUsers);
      return users;
    },
    shownCustomerLicenses: function () {
      var sortFunction = this.getSortFunction();
      var groupedAndSorted = this.groupedCustomerlicenses(sortFunction);

      if (this.filterByEmp >= 0) {
        groupedAndSorted = groupedAndSorted.filter(
          (x) => x.grantedby == this.filterByEmp
        );
      }

      if (!this.showExpired) {
        groupedAndSorted = groupedAndSorted.filter((x) => {
          if (x.expires) {
            return x.expires > Date.now();
          } else {
            return false;
          }
        });
      }

      if (this.filterBy == "granted") {
        groupedAndSorted = groupedAndSorted.filter((x) => x.grantedby);
      } else if (this.filterBy == "pending") {
        groupedAndSorted = groupedAndSorted.filter((x) => x.pending);
      }

      if (this.search) {
        groupedAndSorted = groupedAndSorted.filter((x) => {
          var show = false;

          var customer = this.customerById(x.customerid);
          if (customer)
            show ||= customer.name
              .toLowerCase()
              .includes(this.search.toLowerCase());

          var subcustomer = this.subcustomerById(x.subcustomerid);
          if (subcustomer)
            show ||= subcustomer.name
              .toLowerCase()
              .includes(this.search.toLowerCase());

          var template = this.templateById(x.templateid);
          if (template) {
            show ||= template.name
              .toLowerCase()
              .includes(this.search.toLowerCase());

            var application = this.applicationById(template.applicationid);
            if (application)
              show ||= application.name
                .toLowerCase()
                .includes(this.search.toLowerCase());
          }
          return show;
        });
      }

      return groupedAndSorted;
    },
  },
  data: () => ({
    ready: false,
    expanded: false,
    search: null,
    showExpired: true,
    filterBy: "all",
    filterByEmp: -1,
    filters: [
      { text: "All Licenses", value: "all" },
      { text: "Granted Licenses", value: "granted" },
      { text: "Pending Licenses", value: "pending" },
    ],
    sortBy: "requested-desc",
    sorts: [
      { text: "Requested (Newest first)", value: "requested-desc" },
      { text: "Requested (Oldest first)", value: "requested-asc" },
      { text: "Price (Highest first)", value: "price-desc" },
      { text: "Price (Lowest first)", value: "price-asc" },
      { text: "Expiry (Newest first)", value: "expires-desc" },
      { text: "Expiry (Oldest first)", value: "expires-asc" },
    ],
  }),
  methods: {
    ...mapActions({
      fetchUsersAsync: "fetchUsersAsync",
      fetchCustomerlicensesAsync: "fetchCustomerlicensesAsync",
      fetchCustomersAsync: "fetchCustomersAsync",
      fetchTemplatesAsync: "fetchTemplatesAsync",
      fetchSubcustomersAsync: "fetchSubcustomersAsync",
      getSortedCustomerlicenses: "getSortedCustomerlicenses",
    }),
    getSortFunction: function () {
      switch (this.sortBy) {
        case "requested-asc":
          return function (a, b) {
            return (
              Number(a.purchaseid.split("_")[0]) -
              Number(b.purchaseid.split("_")[0])
            );
          };
        case "requested-desc":
          return function (a, b) {
            return (
              Number(b.purchaseid.split("_")[0]) -
              Number(a.purchaseid.split("_")[0])
            );
          };
        case "price-asc":
          return function (a, b) {
            return Number(a.finalprice) - Number(b.finalprice);
          };
        case "price-desc":
          return function (a, b) {
            return Number(b.finalprice) - Number(a.finalprice);
          };
        case "expires-asc":
          return function (a, b) {
            return Number(a.expires) - Number(b.expires);
          };
        case "expires-desc":
          return function (a, b) {
            return Number(b.expires) - Number(a.expires);
          };
      }
    },
    getLicenseIdsString: function (license) {
      var ids = JSON.parse(JSON.stringify(license.licenseid));
      if (Array.isArray(ids)) {
        ids.sort((a, b) => {
          return a - b;
        });
        return ids[0] + " - " + ids[ids.length - 1];
      } else {
        return license.licenseid;
      }
    },
    getLicenseIdsHoverString: function (license) {
      var ids = JSON.parse(JSON.stringify(license.licenseid));
      if (Array.isArray(ids)) {
        return ids
          .sort((a, b) => {
            return a - b;
          })
          .join(", ");
      } else {
        return false;
      }
    },
    getTemplateName: function (license) {
      var template = this.templateById(license.templateid);
      if (template) return template.name;
      else return "";
    },
    getPartnerName: function (license) {
      var partner = this.customerById(license.customerid);
      if (partner) return partner.name;
      else return "";
    },
    getCustomerName: function (license) {
      var customer = this.subcustomerById(license.subcustomerid);
      if (customer) return customer.name;
      else return "";
    },
    getRequestDate: function (license) {
      var d = new Date(Number(license.purchaseid.split("_")[0]));
      return d.toLocaleString();
    },
    getPrice: function (license) {
      if (license.finalprice) return license.finalprice + "€";
      else return "-";
    },
    getExpiryDate: function (license) {
      if (!license.expires) return "";
      var d = new Date(Number(license.expires));
      return d.toLocaleString();
    },
    getClass: function (license) {
      if (license.pending) return "pending";
    },
  },
  async mounted() {
    await this.fetchUsersAsync();
    await this.fetchCustomerlicensesAsync();
    await this.fetchCustomersAsync();
    await this.fetchTemplatesAsync();
    await this.fetchSubcustomersAsync();
    this.ready = true;
  },
};
</script>
<style scoped>
.pending {
  background-color: #00000020;
}
.nopad {
  padding-left: 0px !important;
}
.title {
  font-size: 2.2rem;
  color: #b31616;
}
</style>